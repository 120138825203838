import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const NewsCard = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: var(--black);
  }
  min-width: ${(props) => (props.home ? "0" : "250px")};  
  max-width: ${(props) => (props.home ? "20vw" : "")}
  width: 100%;
`;

const TextWrapper = styled.div`
  .details {
    display: flex;
    align-items: end;
    padding-left: 5px;
  }

  .summary {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) =>
      props.lineBreaker ? props.lineBreaker : 3}; /* number of lines to show */
    -webkit-box-orient: vertical;
    color: var(--ultramarine);
    margin: 15px 0 6px 0;
    padding: 0 20px 0 5px;
    font-weight: 500;
  }

  .by {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.4rem;
  }

  .date {
    margin: 0 0 0 5px;
    font-size: 1.2rem;
    color: gray;
    white-space: nowrap;
  }
`;

const StyledImg = styled((props) => <GatsbyImage {...props} />)`
  border-radius: 24px;
  width: auto;
  height: ${(props) => (props.home ? "10vw" : "15vw")};
  object-fit: cover;
`;

const ImgWrapper = styled.div`
  border-radius: 10px;
  width: 100%;
`;

const NewsArticle = ({ article, lineBreaker, home }) => {
  return (
    <NewsCard home={home} to={`/news/${article.slug}`}>
      {article.image[0].localFile.childImageSharp && (
        <ImgWrapper>
          <StyledImg
            className={home ? "" : "news-image"}
            alt={`Picture for ${article.title} article`}
            image={article.image[0].localFile.childImageSharp.gatsbyImageData}
            home={home}
          />
        </ImgWrapper>
      )}
      <TextWrapper lineBreaker={lineBreaker}>
        <p className="p2 summary">{article.title}</p>
        <div className="details">
          <p className="by">By {article.by}</p>
          <p className="date">{article.published_at.substring(0, 10)}</p>
        </div>
      </TextWrapper>
    </NewsCard>
  );
};

export default NewsArticle;
