import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import SideBarSocial from "./homePage/sidebar/sidebar-social";
import arrowBack from "../images/arrow_back.svg";

const SideBarSocialContainer = styled.div`
  width: 10vw;
  position: fixed;
  height: 100%;
  margin-top: 4vw;
  overflow: auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  ${
    "" /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  }
  .icon {
    cursor: pointer;
    top: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: var(--blue);
  cursor: pointer;
  top: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const SocialLinksContainer = ({ arrow }) => {
  return (
    <SideBarSocialContainer>
      {arrow && (
        <Link to="/news/">
          <img src={arrowBack} className="icon"></img>
        </Link>
      )}
      <SideBarSocial newsPage="true" />
    </SideBarSocialContainer>
  );
};

export default SocialLinksContainer;
