import React from "react";
import styled from "styled-components";
import Button from "./button";

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f7f7f7;
  padding: 4rem 0 4rem 0;
  bottom: 0;
  position: ${(props) => (props.position ? props.position : "")};

  h2 {
    margin: 1.6rem 0 2.4rem 0;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <h3>Powered by</h3>
      <h2>CAIXA MÁGICA SOFTWARE</h2>
      <a href="https://caixamagica.pt/">
        <Button content="Visit us here" font="2rem" bg="--ultramarine" />
      </a>
    </FooterWrapper>
  );
};

export default Footer;
