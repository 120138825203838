import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../components/font-awesome";

const PaginationWrapper = styled.div`
  display: flex;
  margin: 50px 0 80px 0;

  .pages {
    padding: 9px 16px 8px;
    border-radius: 10px;
    background-color: var(--ultramarine);
    color: white;
    font-size: 1.6rem;
  }

  .button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
  }

  .prev {
    background-color: #dedede;
    margin-right: 12px;
    color: #373737;
  }

  .next {
    color: white;
    margin-left: 12px;  
    background-color: var(--ultramarine);
  }
`;

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext;

  return (
    <PaginationWrapper>
      {previousPagePath && (
        <Link to={previousPagePath}>
          <div className="button prev">
            <FontAwesomeIcon icon="arrow-left" size="2x"/>
          </div>
        </Link>
      )}
      <div className="pages">
        Page {pageContext.pageNumber + 1} out of {pageContext.numberOfPages}
      </div>
      {nextPagePath && (
        <Link to={nextPagePath}>
          <div className="button next">
            <FontAwesomeIcon icon="arrow-right" size="2x"/>
          </div>
        </Link>
      )}
    </PaginationWrapper>
  );
};

Pager.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default Pager;
