import * as React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import NewsArticle from "../components/news_article";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../components/font-awesome";
import PropTypes from "prop-types";
import Pager from "../components/pager";
import Footer from "../components/footer";
import SocialLinksContainer from "../components/socialLinksContainer";
import useSiteMetadata from "../hooks/useSiteMetadata";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../components/mobile/mobileFooter";
import MobileNewsArticle from "../components/mobile/mobileNewsArticle";

//News page is on Templates folder because the need of pagination.
//In the gatsby-node.js file is where we create pagination and resolve the news page.

const Container = styled.div`
  display: flex;
  padding-top: 8rem;
`;

const MainContent = styled.div`
  width: 100%;
  padding: ${(props) => (props.mobile ? "0 3.2rem" : "0 10vw")};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "")};
  padding: ${(props) => (props.mobile ? "4.7rem 0 3.2rem 0" : "80px 0 60px 0")};
  width: 100%;
  justify-content: space-between;
  align-items: ${(props) => (props.mobile ? "center" : "")};

  h1 {
    margin: ${(props) => (props.mobile ? "0.8rem 0 3.2rem 0" : "0")};
    font-size: ${(props) => (props.mobile ? "2.4rem" : "")};
    text-align: ${(props) => (props.mobile ? "center" : "")};
  }

  h3 {
    font-size: ${(props) => (props.mobile ? "2rem" : "")};
    text-align: ${(props) => (props.mobile ? "center" : "")};
  }

  .left-container {
    width: ${(props) => (props.mobile ? "" : "50%")};
  }

  .right-container {
    display: flex;
    align-items: flex-end;
  }
`;

const SearchBar = styled.div`
  height: 6rem;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  margin-bottom: 10px;
  border-radius: 14px;
  //min-width: 200px;
  width: ${(props) => (props.mobile ? "100%" : "30vw")};

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    border-right: solid 1px #eaeaea;
    cursor: pointer;
  }

  svg {
    color: var(--blue);
  }

  .search-text {
    color: var(--blue);
    margin: 0;
    font-size: ${(props) => (props.mobile ? "1.6rem" : "2.4rem")};
    padding: 1.6rem 2.5rem;
    border: none;
    width: 100%;
  }

  .search-text:focus {
    outline: none;
  }
`;

const NewsSection = styled.div`
  display: ${(props) => (props.mobile ? "flex" : "grid")};
  flex-direction: ${(props) => (props.mobile ? "column" : "")};
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  width: 100%;
`;

const SeoWrapper = styled.div``;

const NewsPage = ({ data, pageContext }) => {
  const { siteName } = useSiteMetadata();
  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });

  return (
    <SeoWrapper>
      <Seo
        title={siteName + " | News"}
        description="News about the nearshore outsourcing model and Portugal | Visit us to know the advantages"
      />
      <Layout>
        <Container>
          {!isMobile && <SocialLinksContainer arrow={false} />}
          <MainContent className="main_content" mobile={isMobile}>
            <ContentWrapper mobile={isMobile}>
              <HeaderSection mobile={isMobile}>
                <div className="left-container">
                  <h3>News</h3>
                  <h1>Keep up to date with the latest hiring trends</h1>
                </div>
                {/* 
                  <div className="right-container">
                  <SearchBar mobile={isMobile}>
                    <div className="icon">
                      <FontAwesomeIcon icon="search" size="2x" />
                    </div>
                    <input
                      type="search"
                      className="search-text"
                      placeholder="Search news here"
                    />
                  </SearchBar>
                </div>
                */}
              </HeaderSection>
              <NewsSection mobile={isMobile} className="newsSection">
                {!isMobile &&
                  data.allStrapiArticles.nodes.map((article) => (
                    <NewsArticle article={article} key={article.id} />
                  ))}
                {isMobile &&
                  data.allStrapiArticles.nodes.map((article) => (
                    <MobileNewsArticle article={article} key={article.id} />
                  ))}
              </NewsSection>
              <Pager pageContext={pageContext} />
            </ContentWrapper>
          </MainContent>
        </Container>
        {!isMobile && <Footer />}
        {isMobile && <MobileFooter />}
      </Layout>
    </SeoWrapper>
  );
};

NewsPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default NewsPage;

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allStrapiArticles(
      sort: { fields: published_at, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        slug
        id
        by
        published_at
        title
        summary
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
