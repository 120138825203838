import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const MobileNewsCard = styled((props) => <Link {...props} />)`
  text-decoration: none;
  color: var(--black);
`;

const TextWrapper = styled.div`
  .details {
    display: flex;
    align-items: end;
    padding-left: 5px;
  }

  .summary {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) =>
      props.lineBreaker ? props.lineBreaker : 3}; /* number of lines to show */
    -webkit-box-orient: vertical;
    color: var(--ultramarine);
    margin: 15px 0 6px 0;
    padding: 0 20px 0 5px;
    font-weight: 500;
  }

  .by {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 1.4rem;
  }

  .date {
    margin: 0 0 0 5px;
    font-size: 1.2rem;
    color: gray;
    white-space: nowrap;
  }
`;

const MobileImg = styled((props) => <GatsbyImage {...props} />)`
  border-radius: 24px;
  object-fit: cover;
  height: 45vw;
`;

const MobileNewsArticle = ({ article }) => {
  return (
    <MobileNewsCard to={`/news/${article.slug}`}>
      {article.image[0].localFile.childImageSharp && (
        <MobileImg
          alt={`Picture for ${article.title} article`}
          image={article.image[0].localFile.childImageSharp.gatsbyImageData}
          layout="fullWidth"
        />
      )}
      <TextWrapper>
        <p className="p2 summary">{article.title}</p>
        <div className="details">
          <p className="by">By {article.by}</p>
          <p className="date">{article.published_at.substring(0, 10)}</p>
        </div>
      </TextWrapper>
    </MobileNewsCard>
  );
};

export default MobileNewsArticle;
