import React from "react";
import Button from "../button";
import styled from "styled-components";

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 3.2rem;
  background-color: var(--background2);

  h3 {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 2.2rem;
  }
`;

const MobileFooter = () => {
  return (
    <FooterWrapper id="mobile_footer" className="mobileFooter">
      <h3>Powered by</h3>
      <h2>CAIXA MÁGICA SOFTWARE</h2>
      <a href="https://caixamagica.pt/">
        <Button
          content="Visit us here"
          font="1.6rem"
          bg="--accent"
          className="mobile"
          margin="3.2rem 0 0 0"
        ></Button>
      </a>
    </FooterWrapper>
  );
};

export default MobileFooter;
